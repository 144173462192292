<template>
  <a class="bx-header__logo"
     title="www.maennersache.de"
     :href="getAbsoluteUrl('/')">
    <svg id="logo-wide"
         class="bx-header__logo-wide"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         width="612px"
         height="126.534px"
         viewBox="0 332.733 612 126.534"
         enable-background="new 0 332.733 612 126.534"
         xml:space="preserve">
      <polygon class="bx-header__logo-shadow"
               points="597.814,344.109 0,344.109 0,434.593 572.674,434.593 596.779,459.267 597.188,434.593 " />
      <polygon points="11.376,332.733 11.376,423.217 576.086,423.217 611.567,459.267 611.978,423.217 612,423.217 612,332.733 " />
      <path class="bx-header__logo-char"
            d="M106.046,352.322v51.373H95.739V371.24l-13.981,32.455H74.33l-13.913-32.284v32.308H50.054v-51.396h11.535 l16.495,38.052l16.484-38.052H106.046z" />
      <path fill="#FFFFFF"
            d="M150.788,403.695l-3.311-9.646h-20.01l-3.322,9.646H113.19l18.202-51.521h12.286l18.099,51.521H150.788z M134.156,345.997c-1.884,1.91-4.959,1.931-6.869,0.047c-0.926-0.913-1.447-2.16-1.447-3.46c0-2.683,2.175-4.857,4.858-4.857 c2.683,0,4.857,2.175,4.857,4.857C135.572,343.865,135.067,345.097,134.156,345.997z M130.186,386.097h14.641l-7.36-21.409 L130.186,386.097z M147.773,345.997c-1.884,1.91-4.959,1.931-6.87,0.047c-0.926-0.913-1.447-2.16-1.446-3.46 c-0.003-2.683,2.168-4.86,4.851-4.864c2.683-0.003,4.86,2.169,4.864,4.852c0,0.004,0,0.008,0,0.012 C149.189,343.865,148.684,345.097,147.773,345.997z" />
      <path class="bx-header__logo-char"
            d="M202.604,352.174h10.375v51.521h-10.375l-23.252-35.629v35.629h-10.386v-51.521h10.386l23.252,35.618 V352.174z" />
      <path class="bx-header__logo-char"
            d="M256.515,352.174h10.375v51.521h-10.375l-23.252-35.629v35.629h-10.386v-51.521h10.386l23.252,35.618 V352.174z" />
      <path class="bx-header__logo-char"
            d="M306.455,352.174v7.963h-19.282v13.458h17.063v7.963h-17.063v14.186h19.282v7.963h-29.657v-51.533H306.455z" />
      <path class="bx-header__logo-char"
            d="M326.295,383.23v20.477h-10.387v-51.385h18.918c5.938,0,10.516,1.482,13.731,4.448 c3.13,2.795,4.888,6.815,4.812,11.012c0.007,3.267-1.021,6.453-2.935,9.101c-1.972,2.798-5.066,4.713-9.283,5.745l13.105,21.125 h-12.286l-12.298-20.477L326.295,383.23z M326.295,360.274v15.005h8.531c2.177,0.15,4.322-0.574,5.961-2.014 c1.418-1.462,2.164-3.449,2.06-5.483c0.105-2.031-0.641-4.014-2.06-5.472c-1.634-1.448-3.782-2.178-5.961-2.025L326.295,360.274z" />
      <path class="bx-header__logo-char"
            d="M378.032,359.898c-1.812-0.076-3.596,0.483-5.04,1.582c-1.307,1.069-2.032,2.693-1.956,4.379 c-0.101,1.602,0.417,3.182,1.444,4.414c1.042,1.112,2.344,1.949,3.788,2.435c1.688,0.598,3.409,1.099,5.153,1.501 c1.851,0.447,3.713,0.99,5.586,1.627c1.795,0.604,3.521,1.394,5.153,2.354c1.619,0.981,2.931,2.396,3.788,4.084 c1.012,2.017,1.508,4.252,1.444,6.507c0.009,4.2-1.84,8.189-5.051,10.897c-3.352,3.034-7.815,4.551-13.39,4.551 s-10.075-1.312-13.503-3.936c-3.435-2.621-5.362-6.765-5.153-11.081h11.035c0.394,4.55,2.973,6.807,7.735,6.769 c2.023,0.111,4.016-0.538,5.586-1.82c1.382-1.246,2.137-3.043,2.059-4.903c0.037-1.979-1.004-3.822-2.719-4.812 c-2.021-1.269-4.251-2.167-6.587-2.65c-2.57-0.592-5.164-1.297-7.758-2.139c-2.577-0.812-4.875-2.329-6.633-4.38 c-1.896-2.331-2.864-5.28-2.719-8.282c-0.151-4.316,1.726-8.454,5.074-11.183c3.39-2.798,7.747-4.197,13.07-4.197 c5.324,0,9.62,1.206,12.889,3.617c3.231,2.423,5.006,6.12,5.29,11.069h-11.376c-0.112-1.783-0.902-3.455-2.207-4.675 C381.649,360.437,379.859,359.818,378.032,359.898z" />
      <path class="bx-header__logo-char"
            d="M438.757,403.695l-3.311-9.646h-20.021l-3.311,9.646h-10.967l18.202-51.521h12.285l18.111,51.521H438.757z M418.144,386.097h14.652l-7.338-21.409L418.144,386.097z" />
      <path class="bx-header__logo-char"
            d="M458.232,396.699c-5.05-4.944-7.801-11.774-7.588-18.838c-0.215-7.071,2.551-13.907,7.622-18.839 c4.95-4.91,11.685-7.596,18.656-7.439c5.281-0.091,10.47,1.394,14.902,4.266c4.351,2.857,7.628,7.079,9.316,12.002h-12.444 c-2.275-4.551-6.2-6.807-11.774-6.769c-4.268-0.174-8.406,1.482-11.376,4.55c-2.928,3.034-4.388,7.106-4.38,12.218 c0,5.074,1.456,9.101,4.38,12.218c2.967,3.074,7.107,4.73,11.376,4.55c5.498,0,9.423-2.256,11.774-6.769h12.444 c-1.669,4.934-4.951,9.161-9.316,12.001c-4.438,2.856-9.625,4.33-14.902,4.231C469.947,404.269,463.197,401.603,458.232,396.699z" />
      <path class="bx-header__logo-char"
            d="M542.63,352.322h10.375v51.373H542.63v-22.046h-22.138v22.046h-10.375v-51.373h10.375v21.421h22.138V352.322 z" />
      <path class="bx-header__logo-char"
            d="M592.513,352.174v7.963h-19.339v13.458h17.064v7.963h-17.064v14.186h19.339v7.963h-29.668v-51.533H592.513z" />
    </svg>
    <svg id="logo-small"
         class="bx-header__logo-small"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 110.31 111.23">
      <polygon class="bx-header__logo-shadow"
               points="97.31 10 0 10 0 89.54 75.43 89.54 96.95 111.23 97.31 89.54 97.31 10" />
      <polyline points="78.76 79.54 109.95 111.23 110.31 79.54 110.31 0 10 0 10 79.54 78.41 79.54" />
      <path class="bx-header__logo-char"
            d="M93.22,17.22V62.38H84.16V33.85L71.87,62.38H65.34L53.11,34v28.4H44V17.22H54.14l14.5,33.45L83.13,17.22Z" />
    </svg>
    <span>Männersache</span>
  </a>
</template>

<script>
export default {
  methods: {
    getAbsoluteUrl (url) {
      if (import.meta.env.SSR) return url
      const a = document.createElement('a')
      a.href = url

      return a.href
    }
  }
}
</script>
